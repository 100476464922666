.DashboardProdDiv {
  display: flex;
  flex-direction: column;
  animation: zoomOutCustom2;
  animation-duration: 0.5s;
}
.DashboardProdDiv_div1 {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  background: #000;
  padding: 1em;
  border-radius: 10px;
}
.DashboardProdDiv_div1_cont1_active {
  max-width: 200px;
  width: 100%;
  background: #22ad62;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.DashboardProdDiv_div1_cont1 {
  max-width: 200px;
  width: 100%;
  background: no-repeat;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f6f80;
  font-weight: 500;
  cursor: pointer;
}
.DashboardProdDiv_body {
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: flex-start;
}
.DashboardProdDiv_body_cont1 {
  display: flex;
  /* align-items: center; */
  background: black;
  margin-bottom: 0em;
  padding: 1em;
  border-radius: 15px;
}
.DashboardProdDiv_body_cont1_img {
  width: 200px;
  background: #29292f;
  padding: 1em;
  border-radius: 15px;
  margin-right: 2em;
}
.DashboardProdDiv_body_cont1_img_image {
  width: 100%;
}
.DashboardProdDiv_body_cont1_details {
  display: flex;
  align-items: flex-end;
  /* height: 100%; */
  justify-content: space-between;
  width: 100%;
}
.DashboardProdDiv_body_cont1_details_btn_div_btn {
  width: 120px;
  padding: 10px;
  border-radius: 7px;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  background: #22ad62;
  color: #fff;
}
.DashboardProdDiv_body_cont1_details_txt_title {
  font-size: 24px;
  color: #747483;
  width: 100%;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DashboardProdDiv_body_cont1_details_txt_amount {
  font-size: 32px;
  font-weight: 600;
  color: #e2e2fb;
  margin-top: 10px;
}
.DashboardProdDiv_body_cont1_details_txt {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1610px) {
  .DashboardProdDiv_body_cont1_details_txt_title {
    color: #747483;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .DashboardProdDiv_body_cont1_details_txt_amount {
    font-size: 32px;
    font-weight: 600;
    color: #e2e2fb;
    margin-top: 10px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 1370px) {
  .DashboardProdDiv_body {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media screen and (max-width: 767px) {
  .DashboardProdDiv_body_cont1_img {
    width: 100%;
    max-width: 200px;
    background: #29292f;
    padding: 10px;
    border-radius: 15px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .DashboardProdDiv_body_cont1_details {
    display: flex;
    align-items: flex-end;
    /* height: 100%; */
    justify-content: space-between;
    width: 100%;
    min-width: 130px;
    flex-direction: column;
  }
  .DashboardProdDiv_body_cont1_details_txt {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .DashboardProdDiv_body_cont1_details_txt_title {
    color: #747483;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .DashboardProdDiv_body_cont1_details_txt_amount {
    font-size: 32px;
    font-weight: 600;
    color: #e2e2fb;
    margin-top: 0px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .DashboardProdDiv_body_cont1_details_btn_div {
    width: 100%;
    margin-top: 10px;
  }
  .DashboardProdDiv_body_cont1_details_btn_div_btn {
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    border: 0;
    font-weight: 500;
    font-size: 14px;
    background: #22ad62;
    color: #fff;
  }

  .DashboardProdDiv_div1 {
    padding: 5px;
  }
  .DashboardProdDiv_div1_cont1_active {
    padding: 10px;
  }
  .DashboardProdDiv_div1_cont1 {
    padding: 10px;
  }
}
@media screen and (max-width: 600px) {
  .DashboardProdDiv_body_cont1_img {
    max-width: 120px;
  }
  .DashboardProdDiv_body_cont1_details_txt_title {
    font-size: 14px;
  }
  .DashboardProdDiv_body_cont1_details_txt_amount {
    font-size: 18px;
  }
  .DashboardProdDiv_body_cont1_details_btn_div_btn {
    padding: 7px;
    font-size: 13px;
  }
}
