.header_div {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
}
section.header_section {
  position: relative;
  padding: 1em 0em;
  background: #00000030;
  border-bottom: solid 1px #ffffff33;
  z-index: 1;
  /* backdrop-filter: blur(50px); */
}
.blur_div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: #000; */
  backdrop-filter: blur(50px);
}
.header_section_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  position: relative;
  z-index: 1;
}
.header_section_1 {
  width: 100%;
  display: flex;
  align-items: center;
}
.header_section_2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_section_2_img {
  width: 150px;
}
.header_section_3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header_section_1_menu_btn {
  width: max-content;
  display: flex;
  cursor: pointer;
}
.header_section_3_link {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: solid 1px grey;
  border-radius: 10px;
  color: #fff;
  justify-content: space-between;
  width: 120px;
}
.header_section_3_icon2 {
  font-size: 16px !important;
}
.header_section_1_menu_btn_icon {
  margin-right: 3px;
}
.header_section_3_icon {
  font-size: 20px !important;
  cursor: pointer;
  color: #fff;
}
.headerMenuDiv {
  position: fixed;
  left: 0;
  background: #00000026;
  width: 100%;
  max-width: 600px;
  height: 100svh;
  top: 0;
  padding: 4em;
  backdrop-filter: blur(50px);
  animation: fadeInLeft;
  animation-duration: 0.5s;
  /* animation-delay: 3s; */
}
.headerMenuDiv_cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 4em;
  width: 100%;
  max-width: 350px;
}
.headerMenuDiv_cont_cont1 {
  color: #aeaeae;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 2em;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s linear;
}
.headerMenuDiv_cont_model_list_div_1_hovered {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* margin: auto; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000a1;
  color: #fff;
  font-weight: 500;
  backdrop-filter: blur(10px);
  flex-direction: column;
  /* padding: 2em; */
  animation: moveUp 0.2s linear;
}
.headerMenuDiv_cont_model_list_div_1_hovered_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  text-align: center;
}
@keyframes moveUp {
  0% {
    opacity: 0;
    height: 0%;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
.headerMenuDiv_cont_model_list_div_1_hovered_txt {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.headerMenuDiv_cont_model_list_div_1_hovered_buttons {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  width: 100%;
}
.headerMenuDiv_cont_model_list_div_1_hovered_button1 {
  padding: 10px;
  width: 100%;
  border: solid 1px #fff;
  background: #00000057;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  /* margin-right: 10px; */
  font-size: 14px;
}
.headerMenuDiv_cont_model_list_div_1_hovered_buttons_link1 {
  width: 100%;
  margin-right: 10px;
}
.headerMenuDiv_cont_model_list_div_1_hovered_buttons_link2 {
  width: 100%;
}
.headerMenuDiv_cont_model_list_div_1_hovered_button2 {
  padding: 10px;
  width: 100%;
  border: solid 1px #22ad62;
  background: #22ad62;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
}
.headerMenuDiv_cont_cont1:hover {
  background: #ffffff21;
  border-radius: 11px;
  padding: 1em;
  transition: all 0.2s linear;
}
.headerMenuDiv_cont_cont1_title {
  font-size: 20px;
  font-weight: 500;
}
.headerMenuDiv_cont_cont1_icon {
  font-size: 14px !important;
  color: grey;
}
.headerMenuDiv_cont_backButton {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #a7a7a7;
  cursor: pointer;
}
.headerMenuDiv_cont_model_list_div {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding-bottom: 4em;
  animation: bounceInUp;
  animation-duration: 1s;
  /* margin-bottom: 3em; */
}
.headerMenuDiv_cont_model_list_div::-webkit-scrollbar {
  display: none;
}
.headerMenuDiv_cont_model_list_div_1 {
  position: relative;
  margin-bottom: 2em;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff0a;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 180px;
}
.headerMenuDiv_cont_model_list_div_1_img {
  width: 212px;
}
.header_section_3_link_2 {
  display: flex;
  align-items: center;
  color: #fff;
}
.header_section_3_link_2_prd {
  color: #ababab;
  margin-left: 20px;
}
.header_section_3_mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .header_section_3_mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header_section_3 {
    display: none;
  }
  .header_section_3_link_2_prd {
    display: none;
  }
  .headerMenuDiv_cont {
    margin-top: 1em;
    max-width: 100%;
  }
  .headerMenuDiv {
    padding: 4em 2em;
  }
}
@media screen and (max-width: 500px) {
  .header_section_3_mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header_section_3 {
    display: none;
  }
  .header_section_3_link_2_prd {
    display: none;
  }
  .header_section_2_img {
    width: 150px;
  }
}
