.ProductCheckoutPage_div_section {
  position: relative;
  padding: 8em 0em;
  background: #000;
  color: #fff;
}
.ProductCheckoutPage_div_section_area {
  display: flex;
  justify-content: space-between;
}
.ProductCheckoutPage_div_section_area_1 {
  width: 100%;
  margin-right: 2em;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 10%;
  height: fit-content;
}
.ProductCheckoutPage_div_section_area_1_area1 {
  display: flex;
  flex-direction: column;
}
.ProductCheckoutPage_div_section_area_1_area1_head {
  padding: 1em;
  background: #151515;
  font-size: 24px;
  font-weight: 500;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  color: #9e9e9e;
}
.ProductCheckoutPage_div_section_area_1_area1_body {
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  border: solid 1px #1e1e1e;
}
.ProductCheckoutPage_div_section_area_1_area1_body::-webkit-scrollbar {
  display: none;
}
.ProductCheckoutPage_div_section_area_1_area1_body_1 {
  display: flex;
  align-items: center;
  padding: 1em 0.5em;
  border-bottom: 1px #1e1e1e solid;
}

.ProductCheckoutPage_div_section_area_1_area1_body_1_img_div {
  width: 120px;
  background: #151515;
  border-radius: 10px;
  padding: 1em;
}
.ProductCheckoutPage_div_section_area_1_area1_body_1_img {
  width: 100%;
}
.ProductCheckoutPage_div_section_area_1_area1_body_1_area1 {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  width: 100%;
}
.ProductCheckoutPage_div_section_area_1_area1_body_1_area1_title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.ProductCheckoutPage_div_section_area_1_area1_body_1_area1_amount {
  color: #969696;
}
.ProductCheckoutPage_div_section_area_1_area2 {
  display: flex;
  flex-direction: column;
  border: solid 1px #1e1e1e;
  border-top: 0;
  border-bottom: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
}
.ProductCheckoutPage_div_section_area_1_area2_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1em;
  border-bottom: solid 1px #1e1e1e;
}
.ProductCheckoutPage_div_section_area_1_area2_cont1_title {
  color: #7e7e7e;
}
.ProductCheckoutPage_div_section_area_1_area2_cont1_para {
  font-weight: 500;
}
.ProductCheckoutPage_div_section_area_1_area3 {
  display: flex;
  flex-direction: column;
}
.ProductCheckoutPage_div_section_area_1_area3_title {
  background: #151515;
  padding: 1em;
  font-size: 24px;
  font-weight: 500;
  color: #9e9e9e;
  border-radius: 15px;
}
.ProductCheckoutPage_div_section_area_2 {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.ProductCheckoutPage_div_section_area_2_area1 {
  display: flex;
  flex-direction: column;
}
.ProductCheckoutPage_div_section_area_2_area1_title {
  padding: 1em;
  background: #151515;
  font-size: 24px;
  font-weight: 500;
  border-radius: 15px;
  color: #9e9e9e;
  margin-bottom: 0.5em;
}
.ProductCheckoutPage_div_section_area_2_area1_body {
  display: flex;
  flex-direction: column;
}
.ProductCheckoutPage_div_section_area_2_area1_body_cont {
  width: 100%;
  margin-bottom: 10px;
}
.ProductCheckoutPage_div_section_area_2_area1_body_cont_input {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: solid 1px #1e1e1e;
  background: none;
  color: #878787;
}
.ProductCheckoutPage_div_section_area_2_area2 {
  display: flex;
  flex-direction: column;
}
.ProductCheckoutPage_div_section_area_2_area3 {
  display: flex;
  flex-direction: column;
  border: solid 1px #1e1e1e;
  border-radius: 10px;
}
.ProductCheckoutPage_div_section_area_2_area3_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border-bottom: solid 1px #1e1e1e;
}
.ProductCheckoutPage_div_section_area_2_area3_cont_head {
  color: #7c7c7c;
}
.ProductCheckoutPage_div_section_area_2_area3_cont_para {
  font-weight: 500;
}
.ProductCheckoutPage_div_section_area_2_area3_button {
  padding: 15px;
  border-radius: 10px;
  border: 0;
  background: #22ad62;
  color: #fff;
  font-weight: 500;
}
.ProductCheckoutPage_div_section_area_1_area3_body {
  width: 100%;
  padding: 2em;
  border: solid 1px #1e1e1e;
  border-radius: 10px;
  margin: 10px 0px;
}
.ProductCheckoutPage_div_section_area_1_area3_bodySwiper {
  width: 100% !important;
}
.ProductCheckoutPage_div_section_area_1_area3_bodySwiper_slide {
  width: 90% !important;
}
.swiper-slide.swiper-slide-active.ProductCheckoutPage_div_section_area_1_area3_bodySwiper_slide {
  margin-left: 0em !important;
  width: 90% !important;
  margin-right: 0em !important;
}
.swiper-slide.ProductCheckoutPage_div_section_area_1_area3_bodySwiper_slide.swiper-slide-next {
  margin-left: 2em !important;
  width: 90% !important;
  margin-right: 0em !important;
}
.swiper-slide.swiper-slide-prev.ProductCheckoutPage_div_section_area_1_area3_bodySwiper_slide {
  margin-right: 2em !important;
  margin-left: 8em !important;
  width: 90% !important;
}
/* ===== */
/* ===== */
/* ===== */
.ProductCheckoutPage_div_section_area_1_area3_body_card1 {
  position: relative;
  padding: 3em;
  background: #3d424b;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  border: solid 2px #42454d;
  background-image: linear-gradient(15deg, #000000c4, #00000000);
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1.egc_card {
  background: #89b618;
  border: solid 2px #54ad7c;
  background-image: linear-gradient(37deg, #106441, #0c955063);
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div1 {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  margin-bottom: 4em;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div1_title {
  font-size: 24px;
  color: #c0c0c0;
  font-weight: 500;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div1_title.egc_card {
  color: #a9d9bf;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div1_bal {
  font-size: 48px;
  font-weight: 600;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div2 {
  width: fit-content;
  background: #212121;
  width: 140px;
  font-size: 14px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div2.egc_card {
  background: #094625;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont2_add {
  background: #828282;
  padding: 0px;
  width: 35px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 29px;
  font-weight: 500;
  height: 35px;
  cursor: pointer;
  color: #363636;
  padding-bottom: 4px;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont2_add.egc_card {
  background: #abe295;
  color: #0a592e;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 5% !important;
}
.ProductCheckoutPage_div_section_area_1_area3_bodySwiper {
  width: 100% !important;
  display: flex;
  padding-bottom: 3em;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div1_bal_span {
  font-size: 24px;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div2_icon {
  margin-left: 10px;
}

.ProductCheckoutPage_div_section_area_1_area3_body_card1_cont2 {
  z-index: 1;
  position: relative;
}
.ProductCheckoutPage_div_section_area_1_area3_body_card1_bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.2;
  object-fit: cover;
}
.ProductCheckoutPage_div_section_area_2_area1_body_cont .shimmer-button--lg {
  width: 100% !important;
  border-radius: 5px;
}
.ProductCheckoutPage_div_section_area_1_area1_body_1_img_div
  .shimmer-button--lg {
  width: 100% !important;
  border-radius: 5px;
}
.ProductCheckoutPage_div_section_area_2_area3 .shimmer-button--lg {
  width: 100% !important;
  border-radius: 5px;
}
.ProductCheckoutPage_div_section_area_1_area1_body_1_area1_title
  .shimmer-button--sm {
  width: 100% !important;
  border-radius: 5px;
}
.ProductCheckoutPage_div_section_area_1_area3_body .shimmer-button--lg {
  width: 100% !important;
  height: 200px !important;
  border-radius: 5px;
}
.not_eligible_div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000080;
  z-index: 100;
  backdrop-filter: blur(3px);
  padding: 1em;
}

/* ================= */
/* ================= */
/* ================= */
/* ================= */
/* ================= */
.successCheckoutDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  background: #000000a1;
  z-index: 100;
  padding: 2em 1em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* -webkit-backdrop-filter: blur(3px); */
  backdrop-filter: blur(3px);
  overflow-y: scroll;
}
.successCheckoutDiv_cont {
  padding: 2em;
  background: #29292f;
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: inherit;
  margin: 4em 0;
  animation: zoomIn;
  animation-duration: 0.5s;
}
.successCheckoutDiv_cont_body {
  display: flex;
  flex-direction: column;
  margin-top: 0em;
}
.successCheckoutDiv_cont_body_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}
.successCheckoutDiv_cont_body_1_title {
  font-size: 24px;
  font-weight: 600;
}
.successCheckoutDiv_cont_body_1_para {
  font-size: 14px;
  margin-top: 5px;
  color: #a3a3b6;
}
.successCheckoutDiv_cont_body_2 {
  padding: 1em;
  color: #fff;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  border: solid 1px #494953;
  border-radius: 10px;
}
.successCheckoutDiv_cont_body_2_title {
  font-size: 18px;
  font-weight: 500;
  width: 100%;
}
.successCheckoutDiv_cont_body_2_body {
  font-size: 14px;
  color: #9393a6;
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.successCheckoutDiv_cont_body_2_body_1 {
  padding: 1em;
  border: solid 1px #4a4a55;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-right: 0px;
  margin-bottom: 10px;
  padding-left: 50px;
}
.successCheckoutDiv_cont_body_2_body_1_active {
  border: solid 1px #22ad62;
}
.successCheckoutDiv_cont_body_2_body_1:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 2px solid #ccc;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  border-radius: 7px;
  margin: auto;
}
.successCheckoutDiv_cont_body_2_body_submit {
  padding: 10px;
  border-radius: 7px;
  border: none;
  font-weight: 600;
  color: #fff;
  background: #22ad62;
}
.successModalDiv_cont_animation_icon2 {
  width: 150px;
}

.successCheckoutDiv_cont_body_2_body_1:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

input[type="radio"]:checked + .successCheckoutDiv_cont_body_2_body_1:before {
  background-color: #1c6b41;
  border-color: #22ad62;
  padding: 2px;
}
.pickupFromStoreDiv {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.pickupFromStoreDiv_1_cont {
  padding: 10px;
  border: solid 1px;
  border-radius: 10px;
  margin-top: 1em;
}
.pickupFromStoreDiv_1_cont_1 {
  color: #a2a2a2;
}
.pickupFromStoreDiv_1_cont_2 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
}
.successCheckoutDiv_cont_body_2_body_submit:disabled {
  background: #1d6b42;
  color: #3e9467;
  cursor: not-allowed;
}
/* ================= */
/* ================= */
/* ================= */
/* ================= */
/* ================= */
/* ================= */
/* ================= */
/* ================= */
/* ================= */

@media screen and (max-width: 1023px) {
  .ProductCheckoutPage_div_section_area_1_area3_body_card1 {
    padding: 1em;
  }
}
@media screen and (max-width: 999px) {
  .ProductCheckoutPage_div_section_area_1_area3_body_card1 {
    padding: 1em;
  }
  .ProductCheckoutPage_div_section_area_2 {
    width: 100%;
  }
  .ProductCheckoutPage_div_section_area_1 {
    width: 100%;
    margin-right: 0em;
    margin-bottom: 1em;
    position: relative;
  }
  .ProductCheckoutPage_div_section_area {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .ProductCheckoutPage_div_section_area_1_area3_body {
    padding: 1em;
  }
  .ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div1_bal {
    font-size: 28px;
    font-weight: 600;
  }
  .ProductCheckoutPage_div_section_area_1_area3_body_card1_cont1_div1_title {
    font-size: 16px;
    color: #c0c0c0;
    font-weight: 500;
  }
  .swiper-slide.swiper-slide-prev.ProductCheckoutPage_div_section_area_1_area3_bodySwiper_slide {
    margin-right: 1em !important;
    margin-left: 5em !important;
    width: 90% !important;
  }
  .ProductCheckoutPage_div_section_area_1_area1_body {
    height: 300px;
  }
  .swiper-slide.ProductCheckoutPage_div_section_area_1_area3_bodySwiper_slide.swiper-slide-next {
    margin-left: 1em !important;
  }
  .ProductCheckoutPage_div_section_area_1_area1_body_1_area1_title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
