.dashboardOrder_div_body {
  display: flex;
  flex-direction: column;
}
.dashboardOrder_div_title {
  margin-bottom: 1em;
  font-size: 32px;
  font-weight: 500;
  color: #fff;
}
.DashboardProdDiv_body_cont1_order {
  display: flex;
  /* align-items: center; */
  background: black;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 15px;
}
.dashboardOrder_div {
  display: flex;
  flex-direction: column;
  animation: zoomOutCustom2;
  animation-duration: 0.5s;
}
.dashboard_order_detail_body_1Swiper_slide_img {
  width: 100%;
}
.dashboard_order_detail_body {
  display: flex;
  flex-direction: column;
}
.swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 10% !important;
}
.dashboard_order_detail_body_2_title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 10px;
}
.dashboard_order_detail_body_2_code {
  color: #acacc1;
}
.dashboardOrder_div_tabs {
  margin: 2em 0em;
}
.DashboardWalletsDiv_area2b {
  margin-top: 0em;
  display: flex;
  flex-direction: column;
  background: #000;
  padding: 2em;
  border-radius: 15px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .DashboardWalletsDiv_area2b {
    padding: 1em;
  }
  .dashboardOrder_div_tabs {
    margin: 1em 0em;
  }
}
