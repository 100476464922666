.signup_div_section {
  background: #161616;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 6em 0em;
  position: relative;
  color: #fff;
  padding-top: 6em;
  height: 100%;
  min-height: 100svh;
}
.signup_div_section_div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4em;
}
.signup_div_section_div_title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0.5em;
}
.signup_div_section_div_container_form_cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.signup_div_section_div_container_form_cont_span {
  margin-bottom: 1em;
}
.signup_div_section_div_container_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 2em;
  background: #000;
  border: solid 1px #2f2f2f;
  border-radius: 15px;
  box-shadow: 0px 0px 15px #000;
  position: relative;
}

.signup_div_section_div_container_form_label {
  color: #939393;
  margin-bottom: 10px;
  font-size: 14px;
}
.signup_div_section_div_container_form_input {
  margin-bottom: 15px;
  border-radius: 7px;
  padding: 10px;
  border: solid 1px #383838;
  font-size: 14px;
  color: #97a89f;
  font-weight: 500;
  background: #313131;
}
.signup_div_section_div_container_form_input_pasowrd {
  border-radius: 7px;
  padding: 10px;
  border: solid 1px #383838;
  font-size: 14px;
  color: #97a89f;
  font-weight: 500;
  background: #313131;
  width: 100%;
}
.otp_modal_container_body_icon2 {
  background: #1d1d1d;
  font-size: 24px !important;
  padding: 4px;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
  color: #949494 !important;
  position: absolute;
  right: 2%;
}
.password_div {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.signup_div_section_div_container_form_btn {
  margin-top: 10px;
  padding: 15px;
  border-radius: 10px;
  border: none;
  background: #22ad62;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.select__control {
  color: #97a89f !important;
  background: #313131 !important;
  border: solid 1px #383838 !important;
  padding: 5px;
  border-radius: 7px !important;
  font-size: 14px !important;
  margin-bottom: 15px;
  font-weight: 500;
}
.select__single-value {
  color: #97a89f !important;
}
.select__placeholder {
  color: #97a89f !important;
}
.select__menu {
  color: #97a89f !important;
  background: #313131 !important;
  border: solid 1px #383838 !important;
  margin-top: 0;
}
.select__option--is-focused {
  background: black !important;
}
.select__option--is-selected {
  background: #22ad62 !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 100% !important;
  padding: 15px !important;
  border-radius: 7px !important;
  background: #313131 !important;
  border: solid 1px #383838 !important;
  color: #97a89f !important;
  font-weight: 500 !important;
  padding-left: 3.5em !important;
}

.react-tel-input {
  width: 100% !important;
  margin-bottom: 15px !important;
  border-radius: 7px !important;
}
.react-tel-input .flag-dropdown {
  border: 1px solid #5a5a5a !important ;
  border-radius: 7px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background: #4a4a4a !important;
}
.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #b6b6b6 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #808080 !important;
}
.react-tel-input .country-list {
  background: #313131 !important;
  border-radius: 10px;
  border: solid 1px #454545 !important;
  width: 215px !important;
}
.react-tel-input .country-list::-webkit-scrollbar {
  display: none;
}
.react-tel-input .country-list .country.highlight {
  background-color: #000000 !important;
}
.react-tel-input .country-list .country:hover {
  background-color: #212121 !important;
}
.back_form_btn {
  top: 0;
  margin-bottom: 1em;
  width: fit-content;
  margin-left: -16px;
  margin-top: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.signup_div_section_div_para {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}
.signup_div_section_div_para_link {
  margin-left: 5px;
  color: #22ad62;
  font-weight: 600;
}

.forgot_password_link_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0em;
}
.forgot_password_link_div_1 {
  font-size: 14px;
}
.forgot_password_link_div_2_link {
  color: #86ebb5;
  font-size: 14px;
  font-weight: 500;
}
.signup_div_section_div_container_form_btn:disabled {
  background: #1f3a2b;
  color: #4c7f65;
  cursor: not-allowed;
}
@media screen and (max-width: 600px) {
  .signup_div_section_div {
    margin-top: 0;
  }
}
