.Dashboard_body_area_1 {
  width: 15%;
  /* border-right: solid 1px #2f2f2f; */
  background: #000;
}
.DashboardNav_head_img {
  width: 100%;
  max-width: 140px;
}
.DashboardNav_head {
  padding: 2em 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.DashboardNav {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.DashboardNav_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0em 1em;
  border-bottom: solid 1px #2f2f2f;
  overflow-y: scroll;
}
.DashboardNav_body::-webkit-scrollbar {
  background: #000000 !important;
  width: 10px;
}
.DashboardNav_body::-webkit-scrollbar-thumb {
  background: #2c2c2c !important;
  border-radius: 50px;
}
.DashboardNav_body_1_active {
  background: #22ad6230;
  color: #4ac985;
  padding: 1em 1em;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1em;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
.DashboardNav_body_1_txt {
  width: 100%;
  /* min-width: 75px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DashboardNav_body_1_active::before {
  content: "";
  height: 100%;
  width: 5px;
  position: absolute;
  left: -18px;
  background: #22ad62;
  top: 0px;
  border-radius: 50px;
}
.DashboardNav_body_1 {
  color: #5c5c65;
  padding: 1em 1em;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 1em;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
.DashboardNav_body_1:hover {
  color: #838383;
  background: #1e1e1e;
  border-radius: 10px;
}
.dashNavLogout {
  display: flex;
  align-items: center;
  background: #0b321c;
  padding: 1em 1em;
  cursor: pointer;
  color: #bce1ce;
  font-size: 16px;
  font-weight: 500;
  margin: 0em 1em;
  border-radius: 10px;
  margin-bottom: 1em;
  margin-top: 1em;
}
.DashboardNav_body_1_icon {
  font-size: 24px !important;
  margin-right: 10px;
}
.dashNavLogout_icon {
  font-size: 24px !important;
  margin-right: 10px;
}
.DashboardNav_body_mobile {
  display: none;
}
.DashboardNav_body_1_txt_div {
  position: relative;
  width: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Ping,
.Ping .c-basePart {
  position: absolute;
  height: 0.45rem;
  width: 0.45rem;
  right: 0%;
  left: 95%;
  top: 3%;
}

.Ping .c-basePart,
.Ping .c-flashingPart {
  border-radius: 9999px;
  background-color: #ffffff;
}

.Ping .c-flashingPart {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  position: absolute;
  display: inline-flex;
  height: 100%;
  width: 100%;
  left: 95%;
  top: 3%;
  opacity: 0.75;
}

.animate-ping {
  -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@-webkit-keyframes ping {
  75%,
  to {
    -webkit-transform: scale(5);
    transform: scale(5);
    opacity: 0;
  }
}

@keyframes ping {
  75%,
  to {
    -webkit-transform: scale(5);
    transform: scale(5);
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  .DashboardNav_body_1_active .DashboardNav_body_1_icon {
    animation: rubberBand;
    animation-duration: 1s;
  }
  .Dashboard_body_area_1 {
    width: 100%;
    height: 65px;
    position: fixed;
    z-index: 1000;
    bottom: 0;
  }
  .DashboardNav {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  .DashboardNav_head {
    display: none;
  }
  .DashboardNav_body {
    display: none;
  }
  .DashboardNav_body_mobile {
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0em 0.5em;
    border-bottom: none;
    align-items: center;
    overflow: scroll;
    width: 100%;
    justify-content: space-between;
  }
  .DashboardNav_body_1_active {
    background: none;
    color: #61d797;
    padding: 0.5em 0.5em;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: pulse;
    animation-duration: 0.5s;
  }
  .DashboardNav_body_1_active::before {
    display: none;
  }
  .DashboardNav_body_1 {
    color: #5c5c65;
    padding: 0.5em 0.5em;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0em;
    border-radius: 7px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .dashNavLogout {
    display: none;
  }
  .DashboardNav_body_1_icon {
    font-size: 24px !important;
    margin-right: 0px;
  }
  .DashboardNav_body_1_txt {
    font-size: 12px;
    margin-top: 3px;
  }
  .Ping .c-flashingPart {
    left: 70%;
    top: -90%;
  }
  .Ping,
  .Ping .c-basePart {
    left: 70%;
    top: -90%;
  }
  .DashboardNav_body_1_txt_div {
    text-align: center;
  }
}
