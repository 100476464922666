.dashboardHome_area3 {
  position: relative;
  margin-top: 2em;
}
.TableCompWithDiv_title {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}
.TableCompWithDiv_title_drop_down {
  font-size: 14px;
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3d3d45;
  padding: 18px 15px;
  border-radius: 6px;
  cursor: pointer;
}
.TableCompWithDiv_title_drop_down_div {
  position: absolute;
  right: 0;
  background: #3d3d45;
  border-radius: 8px;
  width: 100px;
  top: 120%;
  overflow: hidden;
}
.TableCompWithDiv_title_btn {
  width: 90px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  background: #22ad62;
  color: #fff;
  font-weight: 500;
}
.TableCompWithDiv_title_drop_down_div_1 {
  color: #a8a8b7;
  font-weight: 500;
  border-bottom: solid 1px #595963;
  padding: 10px;
}
.TableCompWithDiv_title_drop_down_div_1:hover {
  background: #2a2a30;
}
.TableCompWithDiv {
  padding: 2em;
  background: #000;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}
.TableCompWithDiv_cont {
  overflow: scroll;
  height: 100%;
  max-height: 600px;
  border: solid 1px #1f1f24;
  border-radius: 10px;
  margin-top: 10px;
}
.TableCompWithDiv_cont::-webkit-scrollbar {
  display: none;
}
.TableCompWithDiv_cont_table {
  box-sizing: border-box;
  margin: 0px auto;
  min-width: 0px;
  width: 100%;
  display: table;
  padding: 0px;
  margin-top: 0px;
}
.TableCompWithDiv_cont_head {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: table-header-group;
  position: sticky;
  top: 0;
  z-index: 1;
}
.TableCompWithDiv_cont_head_titles {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background: #d0d0d0;
  background: #1f1f24;
}
.TableCompWithDiv_cont_head_titles_div {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;
  color: #9a9a9a;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
}
.TableCompWithDiv_cont_head_titles_div_first {
  position: sticky;
  left: 0;
  z-index: 100;
  background: inherit;
  text-align: left;
  font-size: 13px;
}
.TableCompWithDiv_cont_head_titles_div_first:first-child {
  border-radius: 7px 0px 0px 7px;
  border-bottom-left-radius: 0;
}
.noTransactionData_div {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.stakingTable_body {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
}
.stakingTable_body_row {
  box-sizing: border-box;
  min-width: 0px;
  display: table-row;
  flex-direction: column;
  background: #000000;
  transition: transform 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
  cursor: pointer;
  font-size: 13px;
  border-bottom: solid 1px #33333d;
}
.stakingTable_body_row:hover {
  background: #1c1c20;
}
.stakingTable_body_row_data {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 25px;
  display: table-cell;
  justify-content: space-between;
  white-space: nowrap;
  text-align: center;
  color: #bcbcce;
}
.stakingTable_body_row_data_first {
  position: sticky;
  left: 0;
  /* z-index: 100; */
  background: inherit;
  text-align: left;
}
.stakingTable_body_row_data_last {
  text-align: right;
}
.TableCompWithDiv_cont_head_titles_div_last {
  text-align: right;
}
.TableCompWithDiv_cont_head_titles_div_last:last-child {
  border-radius: 0px 7px 7px 0px;
  /* border: solid; */
  border-bottom-right-radius: 0px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 0em;
}
.normal {
  padding: 5px 10px;
  background: #1f1f24;
  width: fit-content;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.debit {
  padding: 5px 10px;
  background: #431414;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.stakingTable_body_row_data_icon {
  font-size: 14px !important;
  margin-left: 0px;
}
.stakingTable_body_row_data_img {
  width: 10px;
  border-radius: 50%;
  /* opacity: .6; */
  margin-right: 5px;
}
.credit {
  padding: 5px 10px;
  background: #19492e;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.stakingTable_body_row_status_pending {
  padding: 5px 10px;
  background: #b3831840;
  width: fit-content;
  border-radius: 8px;
  color: #ffc23b;
}
.stakingTable_body_row_status_success {
  padding: 5px 10px;
  background: #32cb2b33;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px;
  color: #32cb2b;
}
.stakingTable_body_row_data_last_div {
  display: flex;
  justify-content: flex-end;
}
.stakingTable_body_row_email {
  border: solid 1px #28312c;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 31px;
  margin: auto;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-end;
  gap: 5px;
  justify-content: center;
  margin-top: 1.5em;
}
.page-link {
  position: relative;
  display: block;
  /* padding: 0.5rem 0.75rem; */
  margin-left: -1px;
  line-height: 1.25;
  color: #000000 !important;
  background-color: #1a2b22 !important;
  border: 1px solid #1a251f !important;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #22ad62 !important;
  border-color: #22ad62 !important;
}
.pagination_header_body_cont1 {
  font-size: 22px;
}
.depositMoneyDiv_table_body_title_img_div {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-top: solid 1px #4b4b53; */
  background: #142e15;
}
.depositMoneyDiv_table_body_title_img {
  width: 100px;
}
.SaveReceipt_btn {
  width: 100%;
  /* max-width: 150px; */
  padding: 15px;
  border-radius: 10px;
  border: none;
  background: #22ad62;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SaveReceipt_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 1px #4b4b53;
  padding: 1em;
  padding-top: 2em;
}

.depositMoneyDiv_iconb {
  background: #000000b3;
  border-radius: 5px;
  cursor: pointer;
  font-size: 28px !important;
  padding: 4px;
  position: absolute !important;
  right: 32px;
  top: 20px;
  z-index: 100 !important;
  display: block !important;
  /* display: block !important; */
  color: #fff;
}
.depositMoneyDiv_table_body {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding: 1em 1em;
  /* background: #161619; */
}

.depositMoneyDiv_table_body_title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom: solid 1px #4b4b53;
  padding-bottom: 10px;
}
.depositMoneyDiv_table_body_cont {
  display: flex;
  flex-direction: column;
}
.depositMoneyDiv_table_body_cont_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
  font-size: 14px;
}
.depositMoneyDiv_table_body_cont_1_div1 {
  color: #9797a7;
}
@media screen and (max-width: 767px) {
  .TableCompWithDiv {
    padding: 1em;
  }
  .TableCompWithDiv_title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .TableCompWithDiv_title_btn {
    padding: 8px;
    border-radius: 7px;
    font-size: 12px;
  }
}
