.Dashboard {
  display: flex;
  height: 100svh;
  background: #000000;
  position: relative;
}
.Dashboard_body_area {
  display: flex;
  flex-direction: column;
  width: 85%;
}
.coming_soon {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #cfcfe5;
  font-weight: 500;
}
.Dashboard_body {
  height: 100%;
  background: #29292f;
  padding: 2em;
  position: relative;
  border-radius: 30px;
  overflow-y: scroll;
}
.Dashboard_body::-webkit-scrollbar {
  background: #000000 !important;
  width: 10px;
}

.Dashboard_body::-webkit-scrollbar-thumb {
  background: #2c2c2c !important;
  border-radius: 50px;
}
@media screen and (max-width: 1000px) {
  .Dashboard {
    display: flex;
    height: 100svh;
    background: #000000;
    position: relative;
    flex-direction: column-reverse;
  }
  .Dashboard_body_area {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .Dashboard_body {
    height: 100svh;
    background: #29292f;
    padding: 1em;
    position: relative;
    border-radius: 30px;
    overflow-y: scroll;
    padding-top: 6em;
    padding-bottom: 6em;
  }
}
.ProductLoadingDiv {
  padding: 2em;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100svh; */
}
.prod_loading_cont {
  width: 100%;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.prod_loading_cont_txt {
  margin-top: 1em;
  color: #d3d3df;
}
