.NodataCompDiv {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.NodataCompDiv_cont {
  width: 100%;
  margin: auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NodataCompDiv_cont_icon {
  width: 180px;
}
.NodataCompDiv_cont_icon_iocn {
  width: 100%;
  /* opacity: .6; */
}
.NodataCompDiv_cont_txt {
  color: #b7b7c4;
  margin-top: -1em;
}
