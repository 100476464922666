.HomeDiv {
  display: flex;
  flex-direction: column;
  position: relative;
}
.HomeDivSection1_div_txts_2_link1 {
  margin-right: 1em;
}
.HomeDivSection1 {
  position: relative;
  height: 100%;
  min-height: 100svh;
  overflow-y: auto;
  background: #000;
}
.HomeDivSection1::-webkit-scrollbar {
  display: none;
}
.HomeDivSection1_div {
  display: flex;
  position: relative;
  height: 100%;
  height: 100%;
  min-height: 100svh;
}

.HomeDivSection1_div_video {
  width: 100%;
  height: auto;
  object-fit: cover;
  background: #000;
  animation: zoomOutCustom;
  animation-duration: 1s;
}
@keyframes zoomOutCustom {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoomOutCustom2 {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.HomeDivSection1_div_txts_div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: auto;
  height: 100%;
  z-index: 1;
}
.HomeDivSection1_div_txts {
  display: flex;
  flex-direction: column;
  padding-top: 15em;
}
.HomeDivSection1_div_opacityBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(#00000070, black);
  z-index: 1;
}
.HomeDivSection1_div_txts_1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s; /* don't forget to set a duration! */
}

.HomeDivSection1_div_txts_1_head {
  font-size: 98px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
.HomeDivSection1_div_txts_1_para {
  font-size: 24px;
  color: #d3d3d3;
  width: 60%;
  font-weight: 400;
}
.HomeDivSection1_div_txts_2 {
  display: flex;
  align-items: center;
}
.BgButtonNoBorder {
  padding: 15px;
  width: 100%;
  background: #22ad62;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  max-width: 200px;
  margin-right: 10px;
  border: solid #22ad62;
}
.NoBgButtonWithBorder {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: solid #fff;
  color: #fff;
  background: no-repeat;
  font-weight: 500;
  max-width: 200px;
}
.tricycle_overview_div {
  width: 100%;
  margin-bottom: 4em;
  margin-top: 2em;
  animation: bounceInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s; /* don't forget to set a duration! */
}
.HomeDivSection1_div_txts_div_cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.tricycle_overview_div1 {
  display: flex;
  border: solid 1px #b6b6b642;
  border-radius: 15px;
  overflow: hidden;
}
.tricycle_overview_div1_cont1 {
  color: #fff;
  width: 100%;
  max-width: 479px;
  font-size: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(25px);
  background: #0000002e;
  padding: 1em;
}
.tricycle_overview_div1_cont2 {
  display: flex;
  flex-direction: column;
  padding: 1em;
  backdrop-filter: blur(25px);
  background: #0000002e;
  color: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-left: solid 1px #ffffff17;
  padding: 1em;
}
.tricycle_overview_div1_cont2_head {
  font-size: 32px;
  font-weight: 500;
}
.tricycle_overview_div1_cont2_para {
  font-weight: 300;
}
.HomeDivSection1_div_txts_2_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ====================== HomeDivSection2 styles start===================== */
/* ======================================================================== */
/* ======================================================================== */
.HomeDivSection2 {
  position: relative;
  background: #000000;
  padding: 6em 0em;
}
.HomeDivSection2_area {
  display: flex;
  flex-direction: column;
}
.HomeDivSection2_area1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.HomeDivSection2_area1_cont1 {
  width: 100%;
  font-size: 98px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
.HomeDivSection2_area1_cont2 {
  width: 100%;
  font-size: 18px;
  color: #ababab;
}
.HomeDivSection2_area2 {
  margin-top: 4em;
  position: relative;
}

.HomeDivSection2_area2_img {
  width: 100%;
  border-radius: 25px;
  height: 600px;
  object-fit: cover;
}
.HomeDivSection2_area2_icon {
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HomeDivSection2_area2_icon_icon {
  background: #22ad62;
  padding: 25px;
  font-size: 13px !important;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}
.genVideo {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100svh;
  z-index: 100;
}
.genVideo_close {
  position: absolute;
  right: 2%;
  top: 2%;
  font-size: 32px !important;
  background: #000000a1;
  border-radius: 7px;
  padding: 3px;
  color: #fff;
  cursor: pointer;
  z-index: 1;
}
/* ======================================================================== */
/* ======================================================================== */
/* ====================== HomeDivSection2 styles end===================== */
/* ======================================================================== */
/* ======================================================================== */
/* ====================== HomeDivSection3 styles start===================== */
/* ======================================================================== */
/* ======================================================================== */
/* ======================================================================== */
/* ======================================================================== */
/* ======================================================================== */
.HomeDivSection3 {
  height: 100%;
  min-height: 100svh;
  position: relative;
  padding: 0em 0em;
  overflow: hidden;
}
.HomeDivSection3_bg {
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.HomeDivSection3_area {
  position: relative;
  height: 100%;
}
.HomeDivSection3_area_swiper {
  width: 100%;
  height: 100%;
}
.HomeDivSection3_area_swiper_slide_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 100%;
  min-height: 100svh;
}
.HomeDivSection3_area_swiper_slide_div_img {
  width: 70%;
}
.swiper-slide.swiper-slide-prev.HomeDivSection3_area_swiper_slide {
  width: 45% !important;
  margin-left: -25% !important;
  margin-right: 13% !important;
}
.swiper-slide.swiper-slide-active.HomeDivSection3_area_swiper_slide {
  width: 45% !important;
  margin-left: -5% !important;
  padding: 0em 0em;
}
.swiper-slide.swiper-slide-next.HomeDivSection3_area_swiper_slide {
  width: 45% !important;
  margin-left: 7% !important;
}
.HomeDivSection3_area_swiper_slide_div_txt {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeShow 1s ease-in-out;
}
@keyframes fadeShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HomeDivSection3_area_swiper_slide_div_txt_title {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
}
.HomeDivSection3_area_swiper_slide_div_txt_amount {
  font-size: 24px;
  color: #8f8f8f;
}
.HomeDivSection3_area_swiper_slide_div_specs_div {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 4em;
  animation: fadeShow 1s ease-in-out;
}
.HomeDivSection3_area_swiper_slide_div_specs_div_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0em 10px;
}
.HomeDivSection3_area_swiper_slide_div_specs_div_1_title {
  font-size: 24px;
  color: #fff;
  line-height: 1.2;
  font-weight: 500;
}
.HomeDivSection3_area_swiper_slide_div_specs_div_1_para {
  color: #a4a4a4;
  margin-top: 0.5em;
  line-height: 1.3;
  font-size: 18px;
}
.swiper-button-prev:after {
  font-weight: 700;
  font-size: 24px;
}
.swiper-button-next:after {
  font-weight: 700;
  font-size: 24px;
}
.swiper-button-prev {
  background: #00000047;
  color: #ffffff;
  padding: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #6b6b6b;
  backdrop-filter: blur(4px);
}
.swiper-button-next {
  background: #00000047;
  color: #ffffff;
  padding: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #6b6b6b;
  backdrop-filter: blur(4px);
}
.swiper-pagination-bullet-active {
  background: #22ad62 !important;
  border: solid 1px #22ad62 !important;
}
.swiper-pagination-bullet {
  background: #ffffff29;
  border: solid 1px #ffffff85;
  width: 10px;
  height: 10px;
  opacity: 1;
}
.HomeDivSection3_area_swiper_slide_div_txt_btn {
  width: 125px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  background: #22ad62;
  color: #fff;
}
/* ======================================================================== */
/* ======================================================================== */
/* ======================================================================== */
/* ====================== HomeDivSection3 styles end===================== */

.ProductDetailDiv_last_section_area_txt_area2_link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
.landingPageSectionlast {
  position: relative;
  background: #000;
  padding: 6em 0em;
  overflow: hidden;
  padding-bottom: 0px;
}

.landingPageSectionlast_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.landingPageSectionlast_area_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
}

.landingPageSectionlast_area_1_title {
  font-size: 98px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.landingPageSectionlast_area_1_btn_div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.landingPageSectionlast_area_2 {
  width: 100%;
  max-width: 25em;
  margin: auto;
  height: 330px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.landingPageSectionlast_area_2_img {
  width: 100%;
}

.landingPageSectionlast_area_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landingPageSection1_img_lines {
  position: absolute;
  /* top: 0; */
  width: 100%;
  bottom: 0;
  opacity: 0.5;
}
.landingPageSection1_area1_div3_btn1_link {
  width: 100%;
  max-width: 180px;
  margin-right: 15px;
  text-decoration: none;
}
.landingPageSection1_area1_div3_btn1 {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 49px;
  background: #fff;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landingPageSection1_area1_div3_btn1_cont1 {
  width: 25px;
  margin-right: 10px;
}
.landingPageSection1_area1_div3_btn1_cont1_img {
  width: 100%;
}
.landingPageSection1_area1_div3_btn1_cont2 {
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: #000;
}
.landingPageSection1_area1_div3_btn1_cont2_area1 {
  font-size: 10px;
}
.landingPageSection1_area1_div3_btn1_join {
  padding: 10px;
  border-radius: 49px;
  background: #22ad62;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
}
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
/* =========================== */
@media screen and (max-width: 1023px) {
  .HomeDivSection1_div_txts_1_head {
    font-size: 62px;
    color: #fff;
    font-weight: 600;
  }
  .HomeDivSection1_div_txts_1_para {
    font-size: 20px;
    color: #bababa;
    width: 60%;
    font-weight: 500;
  }

  .tricycle_overview_div1_cont1 {
    padding: 1em;
  }
  .tricycle_overview_div1_cont2_head {
    font-size: 24px;
    line-height: 1;
    text-align: center;
  }
  .tricycle_overview_div1_cont2_para {
    font-size: 14px;
  }
  .HomeDivSection2 {
    padding: 4em 0em;
  }
  .HomeDivSection3_area_swiper_slide_div_txt_title {
    font-size: 32px;
    line-height: 1.2;
  }
  .HomeDivSection3_area_swiper_slide_div_txt_amount {
    font-size: 18px;
    color: #8f8f8f;
  }
  .HomeDivSection3_area_swiper_slide_div_specs_div_1_title {
    font-size: 16px;
  }
  .HomeDivSection3_area_swiper_slide_div_specs_div_1_para {
    font-size: 12px;
  }
  .swiper-slide.swiper-slide-active.HomeDivSection3_area_swiper_slide {
    width: 50% !important;
    margin-left: -10% !important;
  }

  .swiper-slide.swiper-slide-next.HomeDivSection3_area_swiper_slide {
    width: 50% !important;
    margin-left: 2% !important;
  }
  .swiper-slide.swiper-slide-prev.HomeDivSection3_area_swiper_slide {
    width: 50% !important;
    margin-left: -25% !important;
    margin-right: 8% !important;
  }
  .HomeDivSection2_area2 {
    height: 400px;
  }
  .HomeDivSection2_area1_cont1 {
    font-size: 48px;
  }
  .HomeDivSection2_area2_img {
    width: 100%;
    border-radius: 25px;
    height: 100%;
    object-fit: cover;
  }
  .tricycle_overview_div1_cont1 {
    display: none;
  }
  .HomeDivSection1_div_txts {
    padding-top: 8em;
  }
}
@media screen and (max-width: 767px) {
  .tricycle_overview_div1 {
    border-radius: 8px;
  }
  .tricycle_overview_div1_cont1 {
    display: none;
  }
  .tricycle_overview_div1_cont2 {
    padding: 15px 10px;
  }
  .tricycle_overview_div1_cont2_head {
    font-size: 18px;
  }
  .HomeDivSection1_div_txts {
    padding-top: 8em;
  }
  .HomeDivSection2_area1_cont1 {
    font-size: 42px;
    line-height: 1;
  }
  .HomeDivSection2_area2 {
    margin-top: 2em;
  }
  .HomeDivSection2_area2 {
    height: 300px;
  }
  .swiper-slide.swiper-slide-next.HomeDivSection3_area_swiper_slide {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .swiper-slide.swiper-slide-active.HomeDivSection3_area_swiper_slide {
    width: 100% !important;
    margin-left: -34% !important;
  }
  .swiper-slide.swiper-slide-prev.HomeDivSection3_area_swiper_slide {
    width: 100% !important;
    margin-left: -100% !important;
    margin-right: 34% !important;
  }
  .landingPageSectionlast_area_2 {
    height: 290px;
  }

  .landingPageSectionlast_area_1 {
    margin-bottom: 2em;
  }

  .landingPageSectionlast {
    padding: 2em 0em;
    padding-bottom: 0px;
  }
  .landingPageSection1_area1_div3_btn1_link {
    max-width: 200px;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .landingPageSection1_area1_div3_btn1 {
    padding: 10px;
    border-radius: 50px;
    width: 100%;
  }
  .landingPageSection1_area1_div3_btn1_cont1 {
    width: 20px;
  }
  .landingPageSection1_area1_div3_btn1_cont2 {
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-size: 14px;
  }
  .landingPageSectionlast_area_1_btn_div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  .landingPageSectionlast_area_1_title {
    font-size: 48px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .HomeDivSection2_area2 {
    height: 200px;
  }
  .HomeDivSection1_div_txts_1_para {
    font-size: 16px;
    color: #adadad;
    width: 100%;
  }
  .tricycle_overview_div1_cont2 {
    padding: 20px 10px;
  }
  .HomeDivSection1_div_txts_1_head {
    font-size: 48px;
  }
  .HomeDivSection3_area {
    padding: 0em 0;
  }
  .HomeDivSection3_area_swiper_slide_div_txt_title {
    font-size: 28px;
    line-height: 1.2;
  }
  .HomeDivSection2_area1_cont1 {
    font-size: 38px;
    line-height: 1;
  }
  .swiper-button-next {
    padding: 1em;
    height: 10px;
    width: 10px;
  }
  .swiper-button-prev {
    padding: 1em;
    height: 10px;
    width: 10px;
  }
  .swiper-button-next:after {
    font-weight: 800;
    font-size: 12px;
  }
  .swiper-button-prev:after {
    font-weight: 800;
    font-size: 12px;
  }
  .HomeDivSection3_area_swiper_slide_div_specs_div_1 {
    margin: 0em 5px;
  }
  .HomeDivSection3_area_swiper_slide_div_img {
    width: 80%;
  }
  .tricycle_overview_div {
    width: 100%;
  }
  .tricycle_overview_div1_cont2_head {
    font-size: 16px;
  }
  .HomeDivSection1_div_txts {
    padding-top: 6em;
  }
  .HomeDivSection3 {
    padding: 3em 0em;
  }
  .BgButtonNoBorder {
    font-size: 14px;
  }
  .NoBgButtonWithBorder {
    font-size: 14px;
  }
  .landingPageSectionlast_area_1_title {
    font-size: 38px;
    margin-bottom: 20px;
  }
}
