.DashboardHeader {
  padding: 1em 2em;
  background: #000;
  /* border-bottom: solid 1px #313131; */
}
.DashboardHeader_area_2_cont2_cont1_avatar {
  font-size: 16px !important;
  height: 30px !important;
  width: 30px !important;
  background: #22ad62 !important;
  font-weight: 500 !important;
}
.DashboardHeader_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DashboardHeader_area_1 {
  font-size: 42px;
  font-weight: 500;
  color: #b4b4bb;
  width: 100%;
}
.DashboardHeader_area_2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.DashboardHeader_area_2_cont1 {
  position: relative;
}
.DashboardHeader_area_2_cont2 {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.DashboardHeader_area_2_cont2_cont1 {
  margin-right: 6px;
  position: relative;
}
.DashboardHeader_area_2_cont2_cont2 {
  display: flex;
  flex-direction: column;
  line-height: 1;
}
.DashboardHeader_area_2_cont2_cont2_div1 {
  color: #fff;
  font-weight: 600;
}
.DashboardHeader_area_2_cont2_cont2_div2 {
  color: #bbbbc2;
}

.DashboardHeader_area_2_cont1_icon {
  background: grey;
  padding: 5px;
  font-size: 32px !important;
  border-radius: 10px;
  cursor: pointer;
  border: solid 1px #2f2f32;
  background: #161619;
  color: #fff;
}
.DashboardHeader_area_cont_icon {
  display: none !important;
}

.DashboardHeader_area_cont {
  display: flex;
  align-items: center;
  width: 100%;
}

.DashHeaderMenuDiv {
  display: none;
}

@media screen and (max-width: 1000px) {
  .DashboardHeader {
    padding: 1em 1em;
    background: #000;
    /* border-bottom: solid 1px #313131; */
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
  }
  .DashboardHeader_area_1 {
    font-size: 24px;
    font-weight: 500;
    color: #b4b4bb;
    width: 100%;
  }
  .DashboardHeader_area_2_cont2_cont2 {
    display: none;
  }
  .DashboardHeader_area_cont_icon {
    display: block !important;
    background: grey;
    padding: 5px;
    font-size: 32px !important;
    border-radius: 8px;
    cursor: pointer;
    border: solid 1px #2f2f32;
    background: #161619;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
  }
  .DashHeaderMenuDiv {
    display: block;
    position: fixed;
    top: 0;
    background: #0000007d;
    width: 100vw;
    height: 100svh;
    left: 0;
    /* padding: 1em; */
    backdrop-filter: blur(4px);
  }
  .DashHeaderMenuDiv_conts {
    width: 100%;
    max-width: 300px;
    height: 100%;
    background: #161619;
    box-shadow: 7px 0px 13px 5px #00000070;
    padding: 1em;
    animation: fadeInLeft;
    animation-duration: 0.5s;
  }
  .DashHeaderMenuDiv_conts_body {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
  }
  .DashHeaderMenuDiv_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #a6a6b9;
    /* border-bottom: solid 1px #36363e; */
    padding: 1em 0em;
  }
  .dash_head_logout_btn_div {
    margin-top: 1em;
  }
  .dash_head_logout_btn {
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    border: none;
    background: #22ad62;
    color: #fff;
    font-weight: 500;
  }
  .DashHeaderMenuDiv_conts_close_icon {
    background: grey;
    padding: 5px;
    font-size: 32px !important;
    border-radius: 8px;
    cursor: pointer;
    border: solid 1px #2f2f32;
    background: #161619;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
  }
}
