.dashBoard_ref_area1 {
  display: flex;
  justify-content: space-between;
  /* gap: 20px; */
}
.pool_deatail_area {
  display: flex;
  flex-direction: column;
  animation: zoomOutCustom2;
  animation-duration: 0.5s;
}
.pool_deatail_area_member_div {
  margin-bottom: 1em;
}
.referral_banner_bg_div {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1em;
  height: 180px;
}

.referral_banner_bg_img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.welcome_bonus_div {
  align-items: flex-end !important;
}

.dashBoard_ref_area1_cont1 {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 1em;
  border-radius: 8px;
  gap: 0px;
  border: solid 1px #dddddd;
  overflow: auto;
  align-items: center;
  margin-right: 1em;
}

.dashBoard_ref_area1_cont1_div1_cont1_withdraw_btn {
  padding: 10px;
  width: 119px;
  font-size: 14px;
  border-radius: 7px;
  border: none;
  background: #22ad62;
  color: #fff;
  margin-right: 1em;
}

.dashBoard_ref_area1_cont1_div1_cont1_withdraw_btn:disabled {
  background: #c5c4d6;
  color: #fbfbff;
  cursor: not-allowed;
}

.dark .dashBoard_ref_area1_cont1_div1_cont1_withdraw_btn:disabled {
  background: #28513b;
  color: #508367;
}
.dashBoard_ref_area1_cont1_div1_cont2_span {
  font-size: 18px;
  font-weight: 600;
  color: #9494a4;
}
.dashBoard_ref_area1_cont2 {
  display: flex;
  width: 20%;
  padding: 1em;
  background: #fff;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px #dddddd;
}

.dashBoard_ref_area1_cont1_icon_div {
  padding: 8px;
  background: #3f3f47;
  border-radius: 50%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.stackedCoin_icon {
  background: white;
  padding: 3px;
  font-size: 24px !important;
  border-radius: 50%;
  color: #22ad62;
}

.dashBoard_ref_area1_cont1_div1 {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  justify-content: space-between;
}
.dashBoard_ref_area1_cont1_div1_b {
  border-right: solid 1px #3e3e45;
  margin-right: 1em;
}

.dashBoard_ref_area1_cont1_div1_cont1 {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 500;
}

.dashBoard_ref_area1_cont1_div1_cont2 {
  font-weight: 600;
  font-size: 30px;
  white-space: nowrap;
  display: flex;
  align-items: baseline;
}

.engn_symbol_sign {
  font-size: 18px;
  font-weight: 400;
}

.comingSoon_div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff8c;
  backdrop-filter: blur(5px);
}

.dark .comingSoon_div {
  background: #3b4740;
  color: #fff;
}

.comingSoon_div_area {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  text-align: center;
}

.comingSoon-header {
  font-size: 38px;
  font-weight: 600;
  text-align: center;
}

.dashBoard_ref_area2 {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* gap: 20px; */
  margin-top: 1em;
}

.dashBoard_ref_area2_cont1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
  background: #fff;
  padding: 0em;
  border-radius: 8px;
  border: solid 1px #dddddd;
  margin-right: 1em;
}

.dashBoard_ref_area2_cont2 {
  width: 80%;
  /* padding: 1em; */
  display: flex;
  flex-direction: column;
}

.dashBoard_ref_area2_cont1_body {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 1em;
  /* align-items: center; */
}

.dashBoard_ref_area2_cont1_body_div_head {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #8e8b8b;
  padding: 1em 1em;
  background: #f3f3f3;
  border-radius: 7px;
  margin-bottom: 1em;
}

.dashBoard_ref_area2_cont1_body_div1 {
  display: flex;
  justify-content: space-between;
  border: solid 1px #eaeaea;
  border-radius: 6px;
  padding: 1em;
  font-size: 14px;
  margin-bottom: 10px;
}

.dashBoard_ref_area2_cont1_body_div1_cont1 {
  width: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
}

.dashBoard_ref_area2_cont1_body_div_head_cont1 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dashBoard_ref_area2_cont1_body_div_head_cont1_first {
  justify-content: flex-start;
}

.dashBoard_ref_area2_cont1_body_div_head_cont1_last {
  justify-content: flex-end;
}

.dashBoard_ref_area2_cont1_body_div1_cont1_first {
  justify-content: flex-start;
}

.dashBoard_ref_area2_cont1_body_div1_cont1_last {
  justify-content: flex-end;
}

.dashBoard_ref_area2_cont1_head {
  padding: 1em;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.table_hr {
  width: 100%;
  border-bottom: solid 1px #eeeeee;
}

.leaderBoard_icon_div {
  padding: 8px;
  background: #3f3f47;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashBoard_ref_area2_cont1_body_cont {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 435px;
  overflow: auto;
  scroll-behavior: auto;
}
.dashBoard_ref_area2_cont1_body_div1_cont1_last_img {
  width: 12px;
  border-radius: 50%;
  margin-right: 5px;
}
.dashBoard_ref_area2_cont1_body_cont_btn {
  padding: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2em;
  background: #22ad62;
  color: #fff;
}
.dashBoard_ref_area2_cont1_body_cont_btn:disabled {
  background: #1c412d;
  cursor: not-allowed;
}
.leaderBoard_icon {
  background: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 24px !important;
  color: #22ad62 !important;
}

.show_more_btn_div {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show_more_btn {
  width: 100%;
  max-width: 190px;
  border-radius: 6px;
  padding: 10px 0px;
  border: none;
  background: #22ad62;
  color: #fff;
}

.dashBoard_ref_area2_cont2_div1 {
  padding: 0em;
  background: #fff;
  border-radius: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* ============ */
/* ============ */
/* ============ */

.dashBoard_ref_area2_cont2_div2 {
  padding: 1em;
  background: #fff;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1em;
  position: relative;
}

.dashboard_user_refferals_cont {
  padding: 1em;
  border: solid 1px #e7e7e7;
  border-radius: 6px;
  background: #fff;
}

.user_refferals_table {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.see_more_btn_div {
  display: flex;
  align-items: center;
  justify-content: end;
}

.filter_table_area {
  display: flex;
  justify-content: space-between;
}

.toggle_seemore {
  padding: 0.5em 2em;
  border-radius: 8px;
  border: none;
  background: #41ba71;
  color: #fff;
  font-weight: 600;
}

.expanded_refferals_div {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  height: 40em;
  overflow: scroll;
}

.user_refferals_table_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reffer_profit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reffer_transact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user_refferals_table_body_titles {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  color: #41ba71;
  padding: 0em 1em;
}

.referral_default_value {
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 6px;
  border: solid 1px #d6d6d6;
}

.ref_btn {
  width: 100%;
}
.ref_btn:disabled {
  background: #28513b;
  color: #508367;
}
.refferal_copy_btns {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.referral_txt {
  font-size: 16px;
  text-align: center;
}

.ref_btn .tooltiptext {
  display: none;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 26%;
  margin-left: -75px;
  opacity: 1;
  transition: opacity 0.3s;
}

.payViaFortDiv_area_3_input_cont_btn .tooltiptext2 {
  display: none;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 106%;
  left: -100%;
  margin-left: -100px;
  opacity: 1;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.ref_btn {
  padding: 1em 0em;
  background: #22ad62;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 10px;
}

.ref_btn {
  position: relative;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* ======================================= */
/* ======================================= */
/* ======================================= */
/* ======================================= */
/* ======================================= */
/* ======================================= */

.dark .dashBoard_ref_area1_cont1 {
  background: #000;
  border: solid 1px #262538;
  color: #fff;
}

.dark .dashBoard_ref_area1_cont2 {
  background: #000;
  border: solid 1px #262538;
  color: #fff;
}

.dark .dashBoard_ref_area2_cont1 {
  background: #000;
  border: solid 1px #262538;
  color: #fff;
}

.dark .table_hr {
  width: 100%;
  border-bottom: solid 1px #232328;
}

.dark .dashBoard_ref_area2_cont1_body_div1 {
  border: solid 1px #232328;
}

.dark .dashBoard_ref_area2_cont1_body_div_head {
  color: #ffffff;
  background: #232328;
}

.dark .dashBoard_ref_area2_cont2_div1 {
  background: #000;
  border: solid 1px #262538;
  color: #fff;
}

.dark .dashBoard_ref_area2_cont2_div2 {
  background: #000;
  border: solid 1px #262538;
  color: #fff;
}

.dark .referral_default_value {
  border: solid 1px #36363f;
  background: #1f1f24;
  color: #fff;
}

.dark .ref_btn .tooltiptext {
  background-color: #ffffff;
  color: #000;
}

.dark .payViaFortDiv_area_3_input_cont_btn .tooltiptext2 {
  background-color: #ffffff;
  color: #000;
}

.dark .tooltiptext::after {
  border-color: #fff transparent transparent transparent;
}

.dark .tooltiptext2::after {
  border-color: #fff transparent transparent transparent;
}

.dark .dashBoard_ref_area1_cont1_div1_cont1 {
  color: #787690;
}
.dashBoard_ref_area1_cont1_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.dashBoard_ref_area1_cont1__cont1_div1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashBoard_ref_area1_cont1_div1_1 {
  display: flex;
  flex-direction: column;
  text-align: left;
}

@media screen and (max-width: 1100px) {
  .dashBoard_ref_area1 {
    flex-direction: column;
  }

  .dashBoard_ref_area2 {
    flex-direction: column;
  }

  .dashBoard_ref_area2_cont2 {
    width: 100%;
    flex-direction: column;
  }

  .dashBoard_ref_area1_cont2 {
    width: 100%;
  }
  .dashBoard_ref_area2_cont1 {
    margin-right: 0;
    margin-bottom: 1em;
  }
  .dashBoard_ref_area1_cont1 {
    margin-right: 0em;
    margin-bottom: 1em;
    flex-direction: column;
  }
  .dashBoard_ref_area1_cont1_div1_cont1_withdraw_btn {
    width: 100%;
    margin-top: 10px;
  }
  .referral_banner_bg_div {
    height: 110px;
  }
  .dashBoard_ref_area1_cont1__cont1_div1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .dashBoard_ref_area1_cont1_cont1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    margin-bottom: 1em;
  }
  .dashBoard_ref_area1_cont1_div1_b {
    border-right: 0;
    margin-right: 0;
  }
  .dashBoard_ref_area1_cont1_div1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 0px;
    flex-direction: column;
    margin-top: 10px;
  }
  .dashBoard_ref_area1_cont2 {
    align-items: flex-start;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .welcome_bonus_div {
    align-items: flex-start !important;
  }
}

@media screen and (max-width: 500px) {
  .dashBoard_ref_area1_cont1_div1_cont1 {
    font-size: 11px;
  }

  .dashBoard_ref_area1_cont1_div1_cont2 {
    font-size: 18px;
  }

  .stackedCoin_icon {
    font-size: 18px !important;
  }

  .dashBoard_ref_area2_cont1_head {
    font-size: 18px;
    gap: 10px;
  }

  .leaderBoard_icon {
    font-size: 18px !important;
  }

  .dashBoard_ref_area2_cont1_body_div_head {
    font-size: 12px;
    gap: 10px;
  }

  .dashBoard_ref_area2_cont1_body_div1_cont1 {
    width: 100%;
    font-size: 12px;
  }

  .dashBoard_ref_area2_cont1_body_div1 {
    font-size: 12px;
  }
}
