.dashboardHome {
  display: flex;
  flex-direction: column;
  animation: zoomOutCustom2;
  animation-duration: 0.5s;
  /* height: 100%; */
}

.dashboardHome_area1 {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
}
.dashboardHome_area1::-webkit-scrollbar {
  display: none;
}
.dashboardHome_area1_card1 {
  width: 100%;
  min-width: 315px;
  background: #000;
  /* background: #0b321c; */
  padding: 2em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
  color: #80be9b;
  position: relative;
  overflow: hidden;
}
.dashboardHome_area1_card1_last {
  width: 100%;
  background: #000;
  /* background: #0b321c; */
  padding: 2em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0em;
  color: #80be9b;
  position: relative;
}
.dashboardHome_area1_card1_title_div {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  line-height: 1.2;
  color: #fff;
  position: relative;
  z-index: 1;
}

.dashboardHome_area1_card1_icon {
  position: relative;
  z-index: 1;
}
.dashboardHome_area1_card1_title {
  margin-bottom: 0.5em;
}
.dashboardHome_area1_card1_content {
  font-size: 32px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.dashboardHome_area1_card1_content_amnt {
  display: flex;
  align-items: baseline;
}
.dashboardHome_area1_card1_content_btn {
  font-size: 14px;
  padding: 3px;
  border-radius: 27px;
  width: 60px;
  border: 0;
  font-weight: 500;
}
.dashboardHome_area1_card1_icon_icon {
  background: #052212;
  padding: 7px;
  font-size: 32px !important;
  border-radius: 8px;
}
.dashboardHome_area1_card1_content_span {
  font-size: 12px;
  font-weight: 400;
  border: solid 1px #427b5b;
  padding: 8px;
  border-radius: 20px;
  width: 75px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: solid; */
}
.dashboardHome_area1_card1_icon_img {
  width: 30px;
  border-radius: 50%;
}
.dashboardHome_area1_card1_more_icon {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 18px !important;
  cursor: pointer;
}
.dashboardHome_area1_card1_content_symbol {
  font-size: 24px;
  color: #8a9e92;
  font-weight: 500;
}
.start_kyc_div {
  margin-top: 2em;
  background: #000;
  padding: 1em 1em;
  border-radius: 17px;
  margin-top: 2em;
  display: flex;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
}
.start_kyc_div_2 {
  width: 130px;
  background: #22ad62;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}
.start_kyc_div_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.start_kyc_div_1_para {
  font-size: 14px;
  font-weight: 400;
  color: #787882;
  margin-top: 10px;
}

.start_kyc_div_2_link {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
}
.dashboardHome_area2 {
  margin-top: 2em;
  position: relative;
  display: flex;
  flex-direction: column;
}
.dashboardHome_area3_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 2em;
}
.dashboardHome_area3_btn_link {
  width: 150px;
}
.dashboardHome_area3_btn {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 0;
  font-weight: 500;
  color: #fff;
  background: #22ad62;
}
.dash_home_products {
  margin-top: 2em;
  background: #000;
  border-radius: 15px;
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.dash_home_products_title {
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dash_home_products_title_link_btn {
  width: 130px;
  font-size: 16px;
  padding: 10px;
  border: solid 1px #fff;
  background: no-repeat;
  color: #fff;
  border-radius: 9px;
}
.dash_home_products_cont1 {
  position: relative;
  padding: 0em 0em;
  margin-top: 1em;
  /* width: 838px; */
}
.dash_home_products_cont1_mobile {
  display: none;
}
.dash_home_products_swiper {
  width: 100%;
  padding-bottom: 2em;
}
.dash_home_products_swiper
  .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0% !important;
}
/* .dash_home_products_swiper_slide {
  width: 250px !important;
} */
.dash_home_products_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px grey; */
  border-radius: 15px;
}
.dash_home_products_div_1 {
  background: #29292f;
  padding: 1em;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dash_home_products_swiper_slide_img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.dash_home_products_div_body {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.dash_home_products_div_body_title {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dash_home_products_div_body_brand {
  font-size: 14px;
  color: #797984;
  margin: 10px 0px;
}
.dash_home_products_div_body_amount {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dash_home_products_div_body_btn_div {
  margin-top: 10px;
}
.dash_home_products_div_body_btn {
  background: #22ad62;
  color: #fff;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-weight: 500;
}
.dash_home_products_cont1_loading_div {
  display: flex;
  padding: 0em 0em;
  margin-top: 1em;
  overflow: scroll;
}
.dash_home_products_div_1 .shimmer {
  width: 200px !important;
  height: 100px !important;
}
.dash_home_products_div_body_title .shimmer {
  width: 100% !important;
}
.dash_home_products_div_body_amount .shimmer {
  width: 100% !important;
}
.dash_home_products_div_body_btn_div .shimmer {
  width: 100% !important;
}
.dash_home_products_swiper_slide_div_loading {
  margin-right: 20px;
}
@media screen and (max-width: 1280px) {
  .dashboardHome_area1_card1_content_amnt_txt {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 767px) {
  .start_kyc_div {
    align-items: flex-start;
    flex-direction: column;
  }
  .start_kyc_div_2_link {
    width: 100%;
    margin-top: 10px;
  }
  .start_kyc_div_2 {
    width: 100%;
  }
  .start_kyc_div {
    margin-top: 1em;
  }
  .dashboardHome_area2 {
    margin-top: 1em;
  }
  .dashboardHome_area3 {
    margin-top: 1em;
  }
  .dash_home_products {
    margin-top: 1em;
    padding: 1em;
  }
  .dash_home_products_title {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-direction: column; */
  }
  .dash_home_products_title_link_btn {
    width: 80px;
    font-size: 14px;
    padding: 7px;
    border: solid 1px #fff;
    background: no-repeat;
    color: #fff;
    border-radius: 9px;
    margin-left: 10px;
  }
  .dash_home_products_cont1 {
    padding: 0em 1em;
    display: none;
  }
  .dash_home_products_swiper_slide_div {
    margin-right: 15px;
    min-width: 250px;
  }
  .dash_home_products_cont1_mobile {
    display: flex;
    padding: 0em 0em;
    margin-top: 1em;
    overflow: scroll;
  }
  .dash_home_products_cont1_mobile::-webkit-scrollbar {
    display: none;
  }
  .dashboardHome_area1_card1_content {
    font-size: 28px;
  }
  .dashboardHome_area1_card1_content_symbol {
    font-size: 18px;
  }
  .dashboardHome_area1_card1_content_btn {
    font-size: 12px;
    width: 50px;
    color: #000;
  }
  .dashboardHome_area1_card1 {
    padding: 1em;
  }
  .dash_home_products_swiper_slide_img {
    height: 120px;
    border-radius: 10px;
  }
  .dash_home_products_div_1 {
    padding: 0.5em;
  }
  .dash_home_products_swiper_slide_div {
    margin-right: 15px;
    min-width: 215px;
  }
  .dash_home_products_div_body_btn {
    padding: 8px;
    font-size: 14px;
  }
  .dash_home_products_div_body_brand {
    margin: 2px 0px;
  }
  .dash_home_products_div_body_amount {
    font-size: 20px;
  }
}
