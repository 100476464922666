.back {
  background-color: black;
  opacity: 0.8;
}

.signup_section {
  width: 100vw;
  height: 100svh;
  background: #000;
  color: #fff;
  padding-top: 8em;
}
.signup_area {
  width: 100%;
  max-width: 400px;
  border: solid 1px #575757;
  padding: 2em;
  border-radius: 12px;
  margin: auto;
  background: #202020;
}
.signup_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.signup_title {
  font-size: 32px;
  font-weight: 600;
}
.signup_para {
  margin-top: 10px;
}
