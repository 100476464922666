.customAreaChart_div {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  background: #000;
  padding: 2em;
  border-radius: 15px;
}
.customAreaChart_div_chart {
  height: 100% !important;
}

.customAreaChart_div_Title {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}
.customAreaChart_div_tooltip {
  font-size: 24px;
  color: #b9b9b9;
}
.customAreaChart_div_tooltip_span {
  color: #22ad62;
}
text.recharts-text.recharts-cartesian-axis-tick-value {
  fill: #a7a7a7;
}
.customAreaChart_div .shimmer-button--sm {
  margin-top: 10px;
}
.customAreaChart_div .shimmer-button--lg {
  width: 100%;
  height: 100%;
  margin-top: 2em;
}
@media screen and (max-width: 767px) {
  .customAreaChart_div {
    padding: 1em;
    height: 325px;
  }
  .customAreaChart_div_Title {
    font-size: 24px;
  }
  .recharts-text {
    font-size: 12px;
  }
}
