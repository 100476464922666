.successModalDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  background: #00000091;
  backdrop-filter: blur(5px);
  z-index: 100;
}
.successModalDiv_cont {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #222;
  border-radius: 15px;
  border: solid 1px #444444;
  box-shadow: 0px 0px 15px #000;
  padding: 1em;
  animation: zoomIn;
  animation-duration: 0.5s;
}
.successModalDiv_cont_animation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
.successModalDiv_cont_animation_icon {
  width: 250px;
}
.successModalDiv_cont_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
}
.successModalDiv_cont_body_title {
  width: 100%;
  font-size: 42px;
  color: #fff;
  font-weight: 600;
}
.successModalDiv_cont_body_para {
  color: #959595;
  font-size: 18px;
}
.successModalDiv_cont_button_div {
  width: 100%;
  margin-top: 2em;
}
.successModalDiv_cont_button {
  padding: 15px;
  width: 100%;
  border-radius: 15px;
  border: none;
  background: #22ad62;
  color: #fff;
  font-weight: 500;
}
@media screen and (max-width: 600px) {
  .successModalDiv_cont_animation_icon {
    width: 150px;
  }
  .successModalDiv_cont_body_title {
    font-size: 32px;
  }
}
