* {
  margin: 0;
  padding: 0;
  font-family: "Omnes", sans-serif;
  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: "Omnes", sans-serif !important;
  /* cursor: none; */
  -webkit-font-smoothing: antialiased !important;
}
body::-webkit-scrollbar {
  background: #000000 !important;
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  background: #2c2c2c !important;
  border-radius: 50px;
}
.custom_container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0em 1em;
  height: 100%;
}
button {
  transition: 0.3s ease-in-out;
}
button:hover {
  transform: translate3d(0, -5px, 0);
  transition: 0.3s ease-in-out;
}
html {
  scroll-behavior: smooth;
  transition: 2s ease-in;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(64, 64, 64) inset !important;
}
input:focus-visible {
  outline: none;
  border: solid;
  border-color: #21583a;
  border-width: 1px;
  box-shadow: 0px 0px 1px 4px #21583a;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
input:-internal-autofill-selected {
  background-color: #222035a8 !important;
}
::selection {
  background-color: #21583a;
  color: #fff;
}
button:focus {
  outline: unset !important;
}

.App {
  display: flex;
  flex-direction: column;
  position: relative;
}
.custom-cursor {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff12;
  border: solid 1px #fff;
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  backdrop-filter: blur(2px);
}
/* .shimmer {
  background: linear-gradient(
    to right,
    #29292f 8%,
    #5e5e68 18%,
    #29292f 33%
  ) !important;
  animation: shimmer 2.2s linear infinite forwards;
} */
.shimmerCustom {
  background: linear-gradient(
    to right,
    #29292f 8%,
    #5c5c68 18%,
    #29292f 33%
  ) !important;
  background-size: 1000px 100% !important;
  animation: shimmer 2.2s linear infinite forwards !important;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
.shimmer {
  background-image: linear-gradient(
    to right,
    #29292f 8%,
    #5c5c68 18%,
    #29292f 33%
  ) !important;
}
.shimmer-button {
  margin-bottom: 0px !important;
}
.swiper-wrapper {
  align-items: center !important;
}
