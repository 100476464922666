.updatedTokenModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000052;
  z-index: 10000;
  padding: 10px;
  overflow: scroll;
  backdrop-filter: blur(10px);
}
.updatedTokenModal_closeDiv {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: none;
  overflow: scroll;
}
.updatedTokenModal_area {
  width: 100%;
  max-width: 420px;
  height: 100%;
  max-height: 600px;
  border-radius: 20px;
  box-shadow: 3px 5px 20px 10px #000;
  border: solid 1px #484853;
  background: #232328;
  overflow: scroll;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  animation: zoomIn;
  animation-duration: 0.5s;
}
.updatedTokenModal_area1 {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: inherit;
  padding: 1em;
  border-bottom: solid 1px #334b3c;
  z-index: 1;
}
.updatedTokenModal_area1_head {
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.updatedTokenModal_area1_head_close_icon {
  color: #9db6a6;
  cursor: pointer;
}
.updatedTokenModal_area1_para {
  font-size: 12px;
  color: #879e8f;
}
.updatedTokenModal_area1_search {
  margin-top: 10px;
  margin-bottom: 10px;
}
.updatedTokenModal_area1_search_input {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #3a3a42;
  background: #000000;
  color: #fff;
}
.updatedTokenModal_area1_favorites {
  display: flex;
}
.updatedFavoriteToken_cont {
  display: flex;
  width: 100%;
  align-items: center;
  border: solid 1px #334b3c;
  margin-right: 10px;
  padding: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #19241d;
  color: #fff;
}
.updatedFavoriteToken_cont_img {
  width: 23px;
}
.updatedFavoriteToken_cont_title {
  font-size: 14px;
  margin-left: 6px;
}
.updatedTokenModal_area_body {
  /* margin-top: 2em; */
  display: flex;
  flex-direction: column;
  padding: 0;
}
.updatedTokenModal_area_body_area {
  display: flex;
  flex-direction: column;
}
.updatedTokenModal_area_body_area1 {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0em;
  background: none;
  color: #fff;
  padding: 15px;
  transition: 0.1s linear;
}
.updatedTokenModal_area_body_area1:hover {
  background: #324539;
  transition: 0.1s linear;
}
.updatedTokenModal_area_body_area1_cont1 {
  display: flex;
  align-items: center;
}
.updatedTokenModal_area_body_area1_cont1_div1 {
  width: 35px;
}
.updatedTokenModal_area_body_area1_cont1_div1_img {
  width: 100%;
  border-radius: 50%;
}
.updatedTokenModal_area_body_area1_cont1_div2 {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  align-items: flex-start;
}
.updatedTokenModal_area_body_area1_cont1_div2_cont1 {
  font-size: 14px;
}
.updatedTokenModal_area_body_area1_cont1_div2_cont2 {
  font-size: 12px;
  color: #708979;
}
.updatedTokenModal_area_body_area1_cont2 {
  font-size: 12px;
}
.updatedTokenModal_area_body_area1:disabled {
  cursor: not-allowed;
  opacity: 0.2;
  filter: grayscale(1);
}
.updatedFavoriteToken_cont:disabled {
  cursor: not-allowed;
  opacity: 0.2;
  filter: grayscale(1);
}
