html,
/* body {
  overscroll-behavior: none !important;
} */

body {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  max-width: 100% !important;
  -webkit-font-smoothing: antialiased !important;
  background: #000 !important;
  scroll-behavior: smooth !important;
  font-kerning: normal !important;
  -moz-osx-font-smoothing: grayscale !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
